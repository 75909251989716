import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import classNames from "classnames";

import Timer from "./Timer";
import Button from "./Button";
import RotatingCard from "./RotatingCard";

import NEW_YORK_PHOTOS from "../constants/new-york";
import CURACAO_PHOTOS from "../constants/curacao";
import LISBON_PHOTOS from "../constants/lisbon";

import { bodyLargeStyles, device } from "../globalStyles";

import { useErrorsContext } from "../context/errorsContext";
import { useCitiesContext } from "../context/citiesContext";
import { chunkArray, shuffleWithWrongPhotos } from "../constants/utils";
import { useLangContext } from "../context/langContext";

import I18N from "../constants/languages/NL/nl_translations";

import { DURATION } from "../pages/play";

const CardGameContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  margin: auto;
  width: 400px;
  max-width: 100%;
  max-height: calc(100% - 150px);
  flex-grow: 0;
  @media (${device.minMobileXL}) {
    width: 600px;
  }
`;
const CardsRow = styled.div`
  display: flex;
  justify-content: center;
  max-width: 400px;
  @media (${device.minMobileXL}) {
    max-width: 600px;
  }
  max-height: calc(100% / 3);
`;

const DisappearingButton = styled(Button)`
  display: none;
  opacity: 0;
  transition: opacity var(--quad-in-out) 0.3s 0.5s;
  
  &.timesUp {
    display: block;
    opacity: 1;
  }
`;

const TextContainer = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
  flex-grow: 1;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height: 70px;
`;

const BodyLarge = styled.p`
  ${bodyLargeStyles};
`;

type CardGameProps = {
  onComplete: () => void;
  timerCanStart?: boolean;
};

const CardGame = ({ onComplete, timerCanStart = true }: CardGameProps) => {
  const [selected, setSelected] = useState({});
  const [timesUp, setTimesUp] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [city, setCity] = useState(false);
  const [photos, setPhotos] = useState([]);

  const { cities, setCities } = useCitiesContext();

  const { lang } = useLangContext();
  const copy = I18N[lang].play;

  useEffect(() => {
    if (cities.lis || !cities.nyc) {
      setCity(copy.nyc);
      setPhotos(shuffleWithWrongPhotos(NEW_YORK_PHOTOS));
    } else if (cities.cur) {
      setPhotos(shuffleWithWrongPhotos(LISBON_PHOTOS));
      setCity(copy.lis);
    } else if (cities.nyc) {
      setCity(copy.cur);
      setPhotos(shuffleWithWrongPhotos(CURACAO_PHOTOS));
    }
  }, []);

  const handleClick = (id: number) => {
    setSelected((selected) => ({
      ...selected,
      [id]: !selected[id],
    }));
  };

  useEffect(() => {
    if (gameOver) {
      setCities((cities) => ({
        nyc: true,
        cur: cities.lis ? false : cities.nyc,
        lis: cities.lis ? false : cities.cur,
      }));
      onComplete();
      const errors = photos.reduce((acc, photo) => {
        if (!!selected[photo.id] !== photo.valid) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      setErrors(errors);
    }
  }, [gameOver]);

  const { setErrors } = useErrorsContext();

  return (
    <CardGameContainer>
      <TextContainer>
        {gameOver ? (
          <BodyLarge>{copy.title_gameOver}</BodyLarge>
        ) : (
          <BodyLarge>
            {timesUp ? copy.title_timesUp(city) : copy.title(city)}
          </BodyLarge>
        )}
      </TextContainer>

      <CardsContainer>
        {chunkArray(photos, 3).map((photoChunk, index) => (
          <CardsRow key={index}>
            {photoChunk.map((photo, index) => (
              <RotatingCard
                key={photo.id}
                photo={photo}
                index={index}
                selected={selected[photo.id]}
                gameOver={gameOver}
                timesUp={timesUp}
                handleClick={handleClick}
              />
            ))}
          </CardsRow>
        ))}
      </CardsContainer>

      <ButtonContainer>
        {gameOver ? (
          <Button onClick={() => navigate("/final")}>{copy.button}</Button>
        ) : (
          <>
            <Timer
              seconds={DURATION}
              onComplete={() => setTimesUp(true)}
              timerCanStart={timerCanStart}
            />
            <DisappearingButton
              className={classNames({ timesUp })}
              onClick={() => setGameOver(true)}
            >
              {copy.button_results}
            </DisappearingButton>
          </>
        )}
      </ButtonContainer>
    </CardGameContainer>
  );
};

export default CardGame;
