import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { useLangContext } from "../context/langContext";
import I18N from "../constants/languages/NL/nl_translations";

const Seconds = styled.p`
  font-family: "Noa", sans-serif;

  transition: opacity var(--quad-in-out) 0.3s;
  text-align: center;
  height: 40px; // button height
  &.timesUp {
    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

type TimerProps = {
  seconds: number;
  onComplete: () => void;
  timerCanStart?: boolean;
};

const Timer = ({ seconds, onComplete, timerCanStart = true }: TimerProps) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds);

  const { lang } = useLangContext();
  const copy = I18N[lang].play;

  useEffect(() => {
    if (!timerCanStart) return;
    if (secondsLeft > 0) {
      setTimeout(() => {
        setSecondsLeft((s) => s - 1);
      }, 1000);
    }

    if (secondsLeft === 0) {
      onComplete();
    }
  }, [secondsLeft, timerCanStart]);

  return (
    <Seconds className={classNames({ timesUp: secondsLeft === 0 })}>
      {secondsLeft} {secondsLeft > 1 ? copy.seconds : copy.second}
    </Seconds>
  );
};

export default Timer;
